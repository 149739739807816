import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/Layout';

const TosEulaPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>TOS / EULA - Wednesday Night Games, LLC</title>
      </Helmet>

      <Header />
      <main id="main-content">
        <div className="container pt-5">
          <h1 className="h2">
            Wednesday Night Games, LLC
            <br />
            Privacy Policy
          </h1>

          <hr />

          <p>
            <strong>Last Modified:</strong> Feb 15, 2022
          </p>

          <p>
            Wednesday Night Games, LLC values and respects your privacy, and aims to collect as little information as possible. This Privacy Policy details what information is collected from you, why it is collected, and how it is used.
          </p>

          <h2>Definition of Terms</h2>

          <p>
            The terms "We", "Our" and "Us", regardless of capitalization, herein refer to "Wednesday Night Games, LLC."  The terms "You" and "Your" regardless of capitalization, refer to the reader of this Privacy Policy and/or any individuals or entities who use our services.  The term "services", regardless of capitalization, refers to any content or offerings created or provided by us, including, but not limited to, our websites and applications.
          </p>

          <h2>Agreement to Privacy Policy</h2>

          <p>
            By using our services, you agree to this Privacy Policy.  Please check back periodically, as this Privacy Policy may change over time, especially when our apps are updated, or when we introduce new services and features.  We will make you aware of changes to this Privacy Policy by posting the latest Privacy Policy on this page. If the policy changes alter the way in which we process any personally identifiable information, you will be required to "opt-in" before your data will be used for these new purposes. Changes are effective immediately upon being posted on this page.
          </p>

          <h2>Information Collected by Our Applications</h2>

          <p>
            Our applications store data and statistics on your device for the sake of maintaining your preferences and data across uses. This information is stored on your device, and we do not collect it and do not have access to it. To remove this information, delete our applications from your device.
          </p>

          <h2>Information Collected by Our Websites</h2>

          <p>
            Like most websites, when you visit our websites, your browser sends our servers requests for resources, which typically include some or all of the following information:
          </p>

          <ul>
            <li>Your IP address</li>
            <li>Resource/page requested</li>
            <li>Date/time of access</li>
            <li>Page that referred you to our website</li>
            <li>User-Agent data (i.e. information about your browser's type/version)</li>
          </ul>

          <p>
            This information is logged by our servers and is only accessible by system administrators for the following reasons:
          </p>

          <ul>
            <li>Analyzing/auditing network traffic to and from our servers</li>
            <li>Debugging problems with our websites and services</li>
            <li>Preventing malicious behavior</li>
          </ul>

          <p>
            We also make use of CloudFlare for performance and security reasons, and CloudFlare may use cookies to distribute web traffic across servers, identify malicious visitors to websites, reduce the chance of blocking legitimate users, and to provide customized CloudFlare services.  A "cookie" is a piece of data sent from a website to a client, stored on that client's device, and sent back to the server with each request.  See <a href="https://www.cloudflare.com/cookie-policy/">https://www.cloudflare.com/cookie-policy/</a> for more information.
          </p>

          <h2>Information Volunteered To Us By You</h2>

          <p>
            The contact forms on our websites forward voluntarily submit content to us in the form of emails. These emails and other direct emails from you may be stored indefinitely on password-protected mail servers that are accessed through secure connections.  We do not share email correspondence with anyone, except when required by law.
          </p>

          <h2>Third-Parties and Service Providers</h2>

          <p>
            We do not share any information that we collect with third parties, unless required by law, however we do leverage third-party companies and individuals for the purposes of delivering our services to you (e.g. Apple's App Store and the Google Play Store).
          </p>

          <p>
            App stores such as those run by Apple and Google may automatically collect information like app usage statistics, but we are not in control of what types of information are collected by Google, Apple, or any other third-party.
          </p>

          <h2>Links to Other Websites and Services</h2>

          <p>
            Our services may contain links to external websites and services. Note that these external websites and services are not operated by us, and are not governed by our Privacy Policy. Therefore, we strongly advise you to review the Privacy Policy of these external websites and services when following these links. WE HAVE NO CONTROL OVER AND ASSUME NO RESPONSIBILITY FOR THE CONTENT, PRIVACY POLICIES, OR PRACTICES OF ANY EXTERNAL WEBSITES AND SERVICES. ANY LINK TO EXTERNAL WEBSITES AND SERVICES DOES NOT CONSTITUTE OUR AFFILIATION OR SPONSORSHIP WITH THOSE ENTITIES.
          </p>

          <h2>Special Note About Children's Privacy</h2>

          <p>
            We do not knowingly collect any personally identifiable information from children under the age of 13.  If at any point we discover that personally identifiable information has been unintentionally collected from children under the age of 13, we will take reasonable measures to delete that information right away.  If you are a parent or guardian and you are aware that your child has provided us with personally identifiable information, please contact us so that we can delete this information.
          </p>

          <h2>Retrieving and Deleting Personally Identifiable Information</h2>

          <p>
            If you would like to inquire about what personally identifiable information about you we have stored, or if you would like us to delete this information, please contact us by using our <Link to="/#contact">contact form</Link>.  Note that it may not be possible in all cases to delete personally identifiable information collected through our services. In such cases we will notify you of this and provide an explanation as to why this is the case.
          </p>

          <h2>Contact Us</h2>

          <p>
            If you have any questions, please feel free to contact us by using our <Link to="/#contact">contact form</Link>.
          </p>
        </div>
      </main>
      <Footer />
    </Layout >
  )
};

export default TosEulaPage;
